import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { decryptToken } from '../utils/auth';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import './DoctorDashboard.css'; // Import CSS for styling

// Helper function to decode the JWT token
const decodeToken = (token) => {
  const payload = token.split('.')[1]; // Extract the payload part of the JWT
  return JSON.parse(atob(payload)); // Decode the base64-encoded string
};

const DoctorDashboard = () => {
  const [patientId, setPatientId] = useState('');
  const [code, setCode] = useState('');
  const [prescriptions, setPrescriptions] = useState([]);
  const [prescriptionText, setPrescriptionText] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [weight, setWeight] = useState('');
  const [pulse, setPulse] = useState('');
  const [systolicBP, setSystolicBP] = useState('');
  const [diastolicBP, setDiastolicBP] = useState('');
  const [temperature, setTemperature] = useState('');
  const [notes, setNotes] = useState('');
  const [imageUrls, setImageUrls] = useState([]); // Store image URLs after fetching them
  const [image, setImage] = useState(null); // Ensure image is correctly tracked
  const [token, setToken] = useState('');
  const [doctorId, setDoctorId] = useState(''); // New state to store doctorId
  const [activeTab, setActiveTab] = useState('view'); // Active tab state
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const alert = useAlert();
  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    const encryptedToken = localStorage.getItem('doctorToken');
    const decryptedToken = decryptToken(encryptedToken);
    if (!decryptedToken) {
      alert.error('Please login');
      navigate('/login'); // Use navigate instead of history.push
    } else {
      setToken(decryptedToken);

      // Decode the token to extract doctorId
      const decodedToken = decodeToken(decryptedToken);
      setDoctorId(decodedToken.userId); // Set the doctorId from the token payload
    }
  }, [alert, navigate]);

  const fetchPrescriptions = async () => {
    if (!patientId || !code) {
      alert.error('Please enter both Patient ID and Secret Code');
      return;
    }
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`https://prescription-backend-production.up.railway.app/api/prescriptions/history/${patientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { code },
      });
      setPrescriptions(response.data);
      alert.success('Prescriptions fetched successfully');

      // Fetch image URLs for each prescription
      const imagePromises = response.data.map((prescription) =>
        fetchImageUrl(prescription.image, patientId, code)
      );
      const imageUrls = await Promise.all(imagePromises);
      setImageUrls(imageUrls); // Store the fetched image URLs

    } catch (error) {
      alert.error(error.response?.data?.message || 'Failed to fetch prescriptions');
      console.error('Fetch prescriptions error:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // Function to fetch image URL with the token
  const fetchImageUrl = async (filename, patientId, code) => {
    try {
      const response = await axios.get(
        `https://prescription-backend-production.up.railway.app/api/prescriptions/image/${filename}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            patientId,
            code,
          },
          responseType: 'blob', // Important: We are requesting binary data (image)
        }
      );
      return URL.createObjectURL(response.data); // Convert the blob to an object URL for rendering
    } catch (error) {
      console.error('Error fetching image:', error);
      return null; // Return null if there's an error
    }
  };

  const postPrescription = async (e) => {
    e.preventDefault();

    if (!patientId || !prescriptionText || !diagnosis || !image) {
      alert.error('Please fill in all required fields and select an image');
      return;
    }

    setIsLoading(true); // Start loading

    try {
      const formData = new FormData();
      formData.append('text', prescriptionText);
      formData.append('diagnosis', diagnosis);
      formData.append('patientId', patientId);
      formData.append('doctorId', doctorId); // Include doctorId in the request body
      formData.append('image', image); // Add image to the form data

      // Append new fields if they have values
      if (weight) formData.append('weight', weight);
      if (pulse) formData.append('pulse', pulse);
      if (temperature) formData.append('temperature', temperature);
      if (notes) formData.append('notes', notes);

      // Append blood pressure values
      if (systolicBP || diastolicBP) {
        formData.append('bloodPressure[systolic]', systolicBP);
        formData.append('bloodPressure[diastolic]', diastolicBP);
      }

      const response = await axios.post('https://prescription-backend-production.up.railway.app/api/prescriptions/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        alert.success('Prescription uploaded successfully');
        // Reset form fields after successful submission
        setPrescriptionText('');
        setDiagnosis('');
        setWeight('');
        setPulse('');
        setSystolicBP('');
        setDiastolicBP('');
        setTemperature('');
        setNotes('');
        setImage(null);
        fetchPrescriptions(); // Refresh prescriptions
      } else {
        alert.error('Failed to upload prescription');
      }
    } catch (error) {
      alert.error(error.response?.data?.message || 'An error occurred while uploading prescription');
      console.error('Post prescription error:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('doctorToken');
    alert.success('Logged out successfully');
    navigate('/login'); // Use navigate to redirect after logout
  };

  return (
    <div className="doctor-dashboard-container">
      <h2>Doctor Dashboard</h2>

      {/* Tab Navigation */}
      <div className="tab-navigation">
        <button
          className={activeTab === 'view' ? 'active' : ''}
          onClick={() => setActiveTab('view')}
          disabled={isLoading} // Disable while fetching or posting data
        >
          View Prescriptions
        </button>
        <button
          className={activeTab === 'post' ? 'active' : ''}
          onClick={() => setActiveTab('post')}
          disabled={isLoading}
        >
          Post New Prescription
        </button>
      </div>

      {/* Conditionally Render Based on Active Tab */}
      {activeTab === 'view' && (
        <div className="section">
          <h3>View Prescriptions</h3>
          <input
            type="text"
            placeholder="Enter Patient ID"
            value={patientId}
            onChange={(e) => setPatientId(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter Secret Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button onClick={fetchPrescriptions} disabled={isLoading}>View Prescriptions</button>
        </div>
      )}

      {/* Prescriptions List */}
      {activeTab === 'view' && prescriptions.length > 0 && (
        <div className="prescriptions-list">
          <h3>Prescriptions</h3>
          <ul>
            {prescriptions.map((prescription, index) => (
              <li key={prescription._id}>
                <p><strong>Date:</strong> {new Date(prescription.date).toLocaleDateString()}</p>
                <p><strong>Text:</strong> {prescription.text}</p>
                <p><strong>Diagnosis:</strong> {prescription.diagnosis}</p>
                <p><strong>Weight:</strong> {prescription.weight || 'N/A'}</p>
                <p><strong>Pulse:</strong> {prescription.pulse || 'N/A'}</p>
                <p><strong>Blood Pressure:</strong> {prescription.bloodPressure ? `${prescription.bloodPressure.systolic}/${prescription.bloodPressure.diastolic}` : 'N/A'}</p>
                <p><strong>Temperature:</strong> {prescription.temperature || 'N/A'}</p>
                <p><strong>Notes:</strong> {prescription.notes || 'N/A'}</p>
                {imageUrls[index] && (
                  <img src={imageUrls[index]} alt="Prescription" width="200" />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeTab === 'post' && (
        <div className="section">
          <h3>Post a New Prescription</h3>
          <form onSubmit={postPrescription} className="prescription-form">
            <label>Patient ID:</label>
            <input
              type="text"
              value={patientId}
              onChange={(e) => setPatientId(e.target.value)}
              required
            />

            <label>Prescription Text:</label>
            <textarea
              value={prescriptionText}
              onChange={(e) => setPrescriptionText(e.target.value)}
              placeholder="Enter prescription details here..."
              required
            />

            <label>Diagnosis:</label>
            <input
              type="text"
              value={diagnosis}
              onChange={(e) => setDiagnosis(e.target.value)}
              placeholder="Enter diagnosis here..."
              required
            />

            {/* New Fields */}
            <label>Weight (kg):</label>
            <input
              type="number"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="e.g., 70"
            />

            <label>Pulse (bpm):</label>
            <input
              type="number"
              value={pulse}
              onChange={(e) => setPulse(e.target.value)}
              placeholder="e.g., 72"
            />

            <label>Blood Pressure:</label>
            <div className="blood-pressure-inputs">
              <input
                type="number"
                value={systolicBP}
                onChange={(e) => setSystolicBP(e.target.value)}
                placeholder="Systolic (e.g., 120)"
              />
              <span>/</span>
              <input
                type="number"
                value={diastolicBP}
                onChange={(e) => setDiastolicBP(e.target.value)}
                placeholder="Diastolic (e.g., 80)"
              />
            </div>

            <label>Temperature (°C):</label>
            <input
              type="number"
              value={temperature}
              onChange={(e) => setTemperature(e.target.value)}
              placeholder="e.g., 37.5"
            />

            <label>Notes:</label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Additional notes or observations..."
            />

            <label>Upload Image:</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
              capture="environment" // This enables the camera on supported devices
              required
            />

            <button type="submit" disabled={isLoading}>Submit Prescription</button>
          </form>
        </div>
      )}

      {/* Logout Button */}
      <button onClick={handleLogout} className="logout-button" disabled={isLoading}>Logout</button>
    </div>
  );
};

export default DoctorDashboard;
