import React from 'react';
import { FaGithub } from 'react-icons/fa'; // Import GitHub icon from React Icons
import './Header.css'; // Import CSS for styling the header

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <img 
          src="https://images.vexels.com/media/users/3/315395/isolated/preview/1a878e903817a133172498f10dd8742e-apple-pie-cartoon-character.png?w=360" 
          alt="ApplePie Logo" 
          className="logo"
        />
        <h1 className="header-title">ApplePie</h1>
      </div>
      <a 
        href="https://github.com/anmolkys/applepie" 
        target="_blank" 
        rel="noopener noreferrer"
        className="github-link"
      >
        <FaGithub className="github-icon" />
      </a>
    </header>
  );
};

export default Header;
