import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { decryptToken } from '../utils/auth';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { IoSync } from "react-icons/io5";
import { Line } from 'react-chartjs-2';
import './PatientDashboard.css';
import PrescriptionDetails from './PrescriptionDetails';
import { GoogleGenerativeAI } from '@google/generative-ai';
import parse from 'html-react-parser';
import { IoMdArrowBack } from "react-icons/io";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PatientDashboard = () => {
  const [details, setDetails] = useState(null);
  const [secretCode, setSecretCode] = useState('');
  const [expiresAt, setExpiresAt] = useState('');
  const [countdown, setCountdown] = useState('');
  const [prescriptions, setPrescriptions] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('prescriptions');
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();
  const [formattedResponse, setFormattedResponse] = useState('');

  const api = process.env.REACT_APP_GEMINI_API_KEY
  const genAI = new GoogleGenerativeAI(api);
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash-8b" });

  const questions = [
    { id: 1, question: "How was your day overall?" },
    { id: 2, question: "On a scale of 1-10, how energetic do you feel?", type: "number" },
    { id: 3, question: "On a scale of 1-10, how focused were you today?", type: "number" },
    { id: 4, question: "What color represents your mood today?", type: "color" },
    { id: 5, question: "On a scale of 1-10, how calm are you feeling?", type: "number" },
    { id: 6, question: "Did you sleep well? (Yes/No)", type: "text" },
    { id: 7, question: "On a scale of 1-10, how social did you feel?", type: "number" },
    { id: 8, question: "On a scale of 1-10, how much physical activity did you do?", type: "number" },
    { id: 9, question: "Describe any significant events of the day." },
    { id: 10, question: "On a scale of 1-10, how satisfied are you with your day?", type: "number" }
  ];
  
  const [responses, setResponses] = useState(questions.reduce((acc, q) => {
    acc[q.id] = '';
    return acc;
  }, {}));
  
  // Handle response change
  const handleResponseChange = (id, value) => {
    setResponses(prev => ({ ...prev, [id]: value }));
  };

  const handleFormSubmit = async () => {
    const formattedResponses = questions.map(q => `${q.question} ${responses[q.id]}`).join('; ');

    setLoading(true);

    try {
      const prompt = `Please provide insights and advice based on these responses: ${formattedResponses}`;
      const result = await model.generateContent(prompt);
      let botResponse = result.response.text();
      botResponse =  botResponse
      .replace(/(\*\*Key Observations:\*\*)/g, '<b>Key Observations:</b><br>')
      .replace(/(\*\*Advice:\*\*)/g, '<br><b>Advice:</b><br>')
      .replace(/(\*\*Further Questions to Ask:\*\*)/g, '<br><b>Further Questions to Ask:</b><br>')
      .replace(/(\*\*)/g, '') // Remove any other stray asterisks
      .replace(/(?:\*\s)/g, '• ') // Replace bullet points with HTML-friendly bullets
      .replace(/\n/g, '<br>');

      if (botResponse) {
        setFormattedResponse(botResponse);  // Save the response in state
      } else {
        alert.error("Failed to get a valid response from Virtual Assistant");
      }
    } catch (error) {
      alert.error("Failed to get response from Virtual Assistant");
    } finally {
      setLoading(false);
    }
  };

  const backToForm = () => {
    setFormattedResponse('');
  };

  useEffect(() => {
    const encryptedToken = localStorage.getItem('patientToken');
    const token = decryptToken(encryptedToken);

    if (!token) {
      alert.error('Please login');
      navigate('/login');
    } else {
      axios.get('https://prescription-backend-production.up.railway.app/api/patient/details', {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(response => setDetails(response.data))
        .catch(error => alert.error(error.response?.data?.message || 'Failed to fetch details'));

      axios.post('https://prescription-backend-production.up.railway.app/api/secretCodes/generate', {}, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(response => {
          setSecretCode(response.data.code);
          setExpiresAt(new Date(response.data.expiresAt));
        })
        .catch(error => alert.error(error.response?.data?.message || 'Failed to generate secret code'));
    }
  }, [alert, navigate]);

  useEffect(() => {
    if (expiresAt) {
      const interval = setInterval(() => {
        const timeDiff = expiresAt - new Date();
        if (timeDiff <= 0) {
          setCountdown('Expired');
          clearInterval(interval);
        } else {
          const minutes = Math.floor(timeDiff / 60000);
          const seconds = Math.floor((timeDiff % 60000) / 1000);
          setCountdown(`${minutes}m ${seconds}s`);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [expiresAt]);

  const regenerateSecretCode = async () => {
    setIsLoading(true);
    if ((expiresAt - new Date()) > 0) {
      alert.info('Code Not Expired');
      setIsLoading(false);
      return;
    }
    const encryptedToken = localStorage.getItem('patientToken');
    const token = decryptToken(encryptedToken);

    try {
      const response = await axios.post('https://prescription-backend-production.up.railway.app/api/secretCodes/generate', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSecretCode(response.data.code);
      setExpiresAt(new Date(response.data.expiresAt));
      alert.success('Secret code regenerated successfully!');
    } catch (error) {
      alert.error(error.response?.data?.message || 'Failed to regenerate secret code');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPrescriptions = async () => {
    setIsLoading(true);
    const encryptedToken = localStorage.getItem('patientToken');
    const token = decryptToken(encryptedToken);

    try {
      const response = await axios.get('https://prescription-backend-production.up.railway.app/api/patient/prescriptions', {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPrescriptions(response.data.prescriptions);

      const imagePromises = response.data.prescriptions.map(prescription =>
        fetchImageUrl(prescription.image, details.userId)
      );
      const imageUrls = await Promise.all(imagePromises);
      setImageUrls(imageUrls);
    } catch (error) {
      alert.error(error.response?.data?.message || 'Failed to fetch prescriptions');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchImageUrl = async (filename, patientId) => {
    const encryptedToken = localStorage.getItem('patientToken');
    const token = decryptToken(encryptedToken);

    try {
      const response = await axios.get(
        `https://prescription-backend-production.up.railway.app/api/prescriptions/image/${filename}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { patientId },
          responseType: 'blob',
        }
      );
      return URL.createObjectURL(response.data);
    } catch (error) {
      return null;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('patientToken');
    alert.success('Logged out successfully');
    navigate('/login');
  };

  const showPrescriptionDetails = (prescription, imageUrl) => {
    setSelectedPrescription({ prescription, imageUrl });
  };

  const closePrescriptionDetails = () => {
    setSelectedPrescription(null);
  };


  const chartData = prescriptions.map(prescription => ({
    date: new Date(prescription.date).toLocaleDateString(),
    weight: prescription.weight,
    pulse: prescription.pulse,
    systolicBP: prescription.bloodPressure?.systolic,
    diastolicBP: prescription.bloodPressure?.diastolic,
    temperature: prescription.temperature,
  }));

  const weightChartData = {
    labels: chartData.map(prescription => prescription.date),
    datasets: [{
      label: 'Weight (kg)',
      data: chartData.map(prescription => prescription.weight),
      fill: false,
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
    }]
  };

  const pulseChartData = {
    labels: chartData.map(prescription => prescription.date),
    datasets: [{
      label: 'Pulse (bpm)',
      data: chartData.map(prescription => prescription.pulse),
      fill: false,
      backgroundColor: 'rgba(153, 102, 255, 0.6)',
      borderColor: 'rgba(153, 102, 255, 1)',
    }]
  };

  const bloodPressureChartData = {
    labels: chartData.map(prescription => prescription.date),
    datasets: [
      {
        label: 'Systolic BP (mm Hg)',
        data: chartData.map(prescription => prescription.systolicBP),
        fill: false,
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
      },
      {
        label: 'Diastolic BP (mm Hg)',
        data: chartData.map(prescription => prescription.diastolicBP),
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
      }
    ]
  };

  const temperatureChartData = {
    labels: chartData.map(prescription => prescription.date),
    datasets: [{
      label: 'Temperature (°C)',
      data: chartData.map(prescription => prescription.temperature),
      fill: false,
      backgroundColor: 'rgba(255, 206, 86, 0.6)',
      borderColor: 'rgba(255, 206, 86, 1)',
    }]
  };

  return (
    <div className="patient-dashboard-container">
      <div className="tab-navigation">
        <button className={activeTab === 'prescriptions' ? 'active' : ''} onClick={() => setActiveTab('prescriptions')}>Prescriptions</button>
        <button className={activeTab === 'analysis' ? 'active' : ''} onClick={() => setActiveTab('analysis')}>Analysis</button>
        <button className={activeTab === 'virtualAssistant' ? 'active' : ''} onClick={() => setActiveTab('virtualAssistant')}>Virtual Assistant</button>
      </div>

      {activeTab === 'prescriptions' && (
        <>
          <div className="details">
            <p><strong>Patient ID:</strong> {details?.userId}</p>
          </div>
          <div className="secret-code">
            <p><strong>Secret Code:</strong> {secretCode}</p>
            <button onClick={regenerateSecretCode} className={`regenerate-button ${isLoading ? 'spinning' : ''}`} disabled={isLoading}>
              <IoSync />
            </button>
            <p><strong>Expires in:</strong> {countdown}</p>
          </div>
          <button onClick={fetchPrescriptions} className="view-past-button" disabled={isLoading}>View History</button>
          {prescriptions.length > 0 && (
            <div className="prescriptions-grid">
              <h3>Past Prescriptions</h3>
              <div className="prescription-grid-container">
                {prescriptions.map((prescription, index) => (
                  <div className="prescription-card" key={prescription._id}>
                    <p><strong>Date:</strong> {new Date(prescription.date).toLocaleDateString()}</p>
                    <p><strong>Text:</strong> {prescription.text}</p>
                    <p><strong>Diagnosis:</strong> {prescription.diagnosis}</p>
                    <p><strong>Doctor ID:</strong> {prescription.doctorId}</p>
                    {imageUrls[index] && (
                      <img src={imageUrls[index]} alt="Prescription" width="200" />
                    )}
                    <button onClick={() => showPrescriptionDetails(prescription, imageUrls[index])}>More</button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      {activeTab === 'analysis' && (
        <div className="charts-container">
          <h3>Health Analysis</h3>
          <button onClick={fetchPrescriptions} className="view-past-button" disabled={isLoading}>Load Charts</button>
          <div className="chart">
            <Line data={weightChartData} options={{ plugins: { title: { display: true, text: 'Weight Over Time' } } }} />
          </div>
          <div className="chart">
            <Line data={pulseChartData} options={{ plugins: { title: { display: true, text: 'Pulse Over Time' } } }} />
          </div>
          <div className="chart">
            <Line data={bloodPressureChartData} options={{ plugins: { title: { display: true, text: 'Blood Pressure Over Time' } } }} />
          </div>
          <div className="chart">
            <Line data={temperatureChartData} options={{ plugins: { title: { display: true, text: 'Temperature Over Time' } } }} />
          </div>
        </div>
      )}

{activeTab === 'virtualAssistant' && (
        <div className="form-container">
        <h3>Daily Reflection Form</h3>
    
        {!formattedResponse ? (
          <form onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
            {questions.map((q) => (
              <div key={q.id} className="form-question">
                <label>{q.question}</label>
                {q.type === 'color' ? (
                  <input
                    type="color"
                    value={responses[q.id]}
                    onChange={(e) => handleResponseChange(q.id, e.target.value)}
                    className="color-input"
                  />
                ) : q.type === 'number' ? (
                  <>
                  <div className='ranges'>
                    <input
                      type="range"
                      min="1"
                      max="10"
                      value={responses[q.id]}
                      onChange={(e) => handleResponseChange(q.id, e.target.value)}
                      className="slider-input"
                    />
                    <span>{responses[q.id]}</span> {/* Display slider value */}
                  </div>
                  <hr style={{color:"black"}}></hr>
                  </>
                ) : (
                  <>
                  <input
                    type="text"
                    value={responses[q.id]}
                    onChange={(e) => handleResponseChange(q.id, e.target.value)}
                    className="text-input"
                  />
                  <hr></hr>
                  </>
                )}
              </div>
            ))}
            <button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        ) : (
          <div>
            <IoMdArrowBack onClick={backToForm} className='backArrow'></IoMdArrowBack>
            <div className="formatted-response">{parse(formattedResponse)}</div>
          </div>
        )}
        {loading && <div className="loader"></div>}
      </div>
      )}
      {selectedPrescription && (
        <div className="prescription-popup-overlay">
          <PrescriptionDetails
            prescription={selectedPrescription.prescription}
            imageUrl={selectedPrescription.imageUrl}
            onClose={closePrescriptionDetails}
          />
        </div>
      )}

      <button onClick={handleLogout} className="logout-button" disabled={isLoading}>Logout</button>
    </div>
  );
};

export default PatientDashboard;