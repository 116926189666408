import React from 'react';
import { Navigate } from 'react-router-dom';

// Function to check if the user is authenticated
const isAuthenticated = () => {
  const role = localStorage.getItem('role'); // Get role from localStorage
  const token = localStorage.getItem(`${role}Token`); // Get the correct token based on role
  console.log('Checking auth: role:', role, 'token:', token); // Debugging auth check
  return role && token; // Ensure both role and token exist
};

// Function to get the user's role
const getUserRole = () => {
  const role = localStorage.getItem('role');
  console.log('Retrieved role from storage:', role); // Debugging role retrieval
  return role;
};

const ProtectedRoute = ({ children, roleRequired }) => {
  const userRole = getUserRole();

  if (!isAuthenticated()) {
    // If the user is not authenticated, redirect to the login page
    console.log('Not authenticated, redirecting to login'); // Debugging redirect
    return <Navigate to="/login" />;
  }

  if (roleRequired && userRole !== roleRequired) {
    // If the user's role does not match the required role, redirect to login
    console.log('Role mismatch, redirecting to login'); // Debugging role mismatch
    return <Navigate to="/login" />;
  }

  // If the user is authenticated and their role is correct, render the children components
  return children;
};

export default ProtectedRoute;
