import React from 'react';
import { IoClose } from "react-icons/io5";
import './PrescriptionDetails.css';

const PrescriptionDetails = ({ prescription, imageUrl, onClose }) => {
  return (
    <div className="prescription-popup">
      <button className="close-popup-button" onClick={onClose}>
        <IoClose /> {/* Close Icon */}
      </button>
      <div className="prescription-details">
        <h2>Prescription Details</h2>
        <p><strong>Date:</strong> {new Date(prescription.date).toLocaleDateString()}</p>
        <p><strong>Text:</strong> {prescription.text}</p>
        <p><strong>Diagnosis:</strong> {prescription.diagnosis}</p>
        <p><strong>Doctor ID:</strong> {prescription.doctorId}</p>
        <div className="prescription-image">
          <img src={imageUrl} alt="Prescription" />
        </div>
      </div>
    </div>
  );
};

export default PrescriptionDetails;
