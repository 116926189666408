import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p className="copyright">
        Copyright
        &copy; ~  <a href="https://github.com/anmolkys" target="_blank" rel="noopener noreferrer">
          anmolkys
        </a>
        &nbsp;
         2024
      </p>
    </footer>
  );
};

export default Footer;