import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import './index.css';
// Alert options
const options = {
  position: 'top right',  // Adjust the alert's position
  timeout: 3000,
  offset: '30px',
  transition: 'scale',
  containerStyle: {
    zIndex: 1000, // Ensure it's higher than the header
    position: 'fixed',
    top: '10px',
    right: '10px',
  },
};

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
