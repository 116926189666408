import React, { useState } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { useNavigate, Link } from 'react-router-dom';
import './Signup.css'; // Import CSS for styling
import Footer from './Footer';

const Signup = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('patient'); // Default role
  const [speciality, setSpeciality] = useState('');
  const [hospital, setHospital] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [bloodGroup, setBloodGroup] = useState('');
  const [age, setAge] = useState('');
  const alert = useAlert();
  const history = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        userId,
        password,
        name,
        email,
        role,
      };

      if (role === 'doctor') {
        payload.speciality = speciality;
        payload.hospital = hospital;
      } else if (role === 'patient') {
        payload.height = height;
        payload.weight = weight;
        payload.bloodGroup = bloodGroup;
        payload.age = age;
      }

      const response = await axios.post('https://prescription-backend-production.up.railway.app/api/auth/signup', payload);

      if (response.status === 201) {
        alert.success('Signup successful! Please login.');
        history('/login');
      }
    } catch (error) {
      if (error.response) {
        alert.error(error.response.data.message || 'Signup failed');
      } else {
        alert.error('An error occurred during signup');
      }
      console.error('Signup error:', error);
    }
  };

  return (
    <>
    <div className="signup-container">
      <h2>Signup</h2>
      <form onSubmit={handleSignup} className="signup-form">
        <label>User ID:</label>
        <input
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          required
        />

        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label>Role:</label>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="patient">Patient</option>
          <option value="doctor">Doctor</option>
        </select>

        {/* Doctor-specific fields */}
        {role === 'doctor' && (
          <>
            <label>Speciality:</label>
            <input
              type="text"
              value={speciality}
              onChange={(e) => setSpeciality(e.target.value)}
              required
            />

            <label>Hospital:</label>
            <input
              type="text"
              value={hospital}
              onChange={(e) => setHospital(e.target.value)}
              required
            />
          </>
        )}

        {/* Patient-specific fields */}
        {role === 'patient' && (
          <>
            <label>Height (cm):</label>
            <input
              type="number"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              required
            />

            <label>Weight (kg):</label>
            <input
              type="number"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              required
            />

            <label>Blood Group:</label>
            <input
              type="text"
              value={bloodGroup}
              onChange={(e) => setBloodGroup(e.target.value)}
              required
            />

            <label>Age:</label>
            <input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
            />
          </>
        )}

        <button type="submit">Signup</button>
      </form>
      <p className="signup-text">
        Account Exists? <Link to="/login">Login</Link>
      </p>
    </div>
    <Footer></Footer>
    </>
  );
};

export default Signup;
