import CryptoJS from 'crypto-js';

// Use the secret key from environment variables
const secretKey = process.env.REACT_APP_SECRET_KEY;
console.log(secretKey)

export function encryptToken(token) {
  return CryptoJS.AES.encrypt(token, secretKey).toString();
}

export function decryptToken(encryptedToken) {
  if (!encryptedToken) return null;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
    const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedToken || null;
  } catch (error) {
    console.error('Decryption error:', error);
    return null;
  }
}
